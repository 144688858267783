import React, { useRef } from 'react'
import { withTranslation } from 'react-i18next'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { flagDataTypes } from 'types/general'

import { TRANSITION_TIMING } from 'constants/sidebar'
import { TYPOGRAPHY } from '@junglescout/styles-library'

// import { media } from 'helpers/media_queries'
import { showSidebarItem } from 'helpers/sidebar'

import { Icon, Text } from '@junglescout/edna'

const NAV_ITEM_HEIGHT = 48

const StyledChevronIcon = styled(Icon).attrs({
  name: 'CHEVRON_DOWN',
  color: 'grey200'
})`
  transition: transform 0.3s ease-in-out;
  transform: ${props => (props.direction === 'up' ? 'rotate(180deg)' : 'none')};
  visibility: hidden;
`

const ItemWrapper = styled.div`
  width: 100%;
  position: relative;
  height: ${NAV_ITEM_HEIGHT}px;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  flex-shrink: 0;

  &:hover {
    ${StyledChevronIcon} {
      visibility: visible;
    }
  }

  ${props =>
    props.isExpanded &&
    !props.collapsed &&
    `
      height: ${props.expandedHeight}px;
    `}

  &:hover {
    ${props =>
      !props.isActive && `background-color: ${props.theme.colors.grey950};`}
  }

  // Active indicator
  &::after {
    content: '';
    width: 4px;
    top: 2px;
    bottom: 2px;
    border-radius: 0 4px 4px 0;
    position: absolute;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${props => props.theme.colors.grey950};
      &::after {
        background-color: ${props.theme.colors.primarySidenav};
      }
    `}

  ${props =>
    props.isExpanded &&
    !props.isActive &&
    css`
      background-color: ${props.theme.colors.grey950};
    `}
`

const activeStatus = props => css`
  color: ${props.theme.colors.white};
  svg:first-of-type {
    color: ${props.theme.colors.primarySidenav};
  }
`

const linkStyles = props => css`
  ${TYPOGRAPHY.headingSm};
  font-weight: 500;
  display: flex;
  width: 100%;
  height: ${NAV_ITEM_HEIGHT}px;
  align-items: center;
  transition: all 0.2s ease;
  color: ${props.theme.colors.grey200};
  cursor: pointer;
  ${props.active &&
    `
    color: ${props.theme.colors.primarySidenav};
    ${activeStatus(props)}
  `}
  ${!props.active &&
    props.isExpanded &&
    `background-color: ${props =>
      props.theme.colors.grey950};`}
  &:hover,
  &.active {
    ${activeStatus(props)};
    background-color: ${props.theme.colors.grey950};
  }
  ${props.item && props.item.isActive && activeStatus(props)};
`

const CustomNav = styled.div`
  ${linkStyles}
  width: 100%;
  transition: width 0.3s ${TRANSITION_TIMING};
`

const CustomAnchor = styled.a`
  ${linkStyles}
`

const CustomNavLink = styled(NavLink)`
  ${linkStyles}
`

const ChildLink = styled(NavLink)`
  ${TYPOGRAPHY.bodySm};
  font-weight: 500;
  display: flex;
  width: 100%;
  align-items: center;
  transition: color 0.2s ease;
  color: ${props => props.theme.colors.grey200};
  padding-left: 46px;
  height: 27px;
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: ${props => (props.isExpanded ? 1 : 0)};
  position: relative;
  ${props =>
    props.active &&
    `
    color: ${props.theme.colors.primarySidenav};
    ${activeStatus}
    circle {
      stroke: ${props.theme.colors.grey900};
    }
  `}
  &:hover,
  &.active {
    ${activeStatus}
    circle {
      stroke: ${props => props.theme.colors.grey900};
    }
  }
  &.active {
    color: ${props => props.theme.colors.primarySidenav};
  }
`

export const ItemText = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;
  opacity: ${props => (props.collapsed ? '0' : '1')};
  white-space: ${props => (props.enableWrapping ? 'pre' : 'nowrap')};
  transition: opacity 0.3s ${TRANSITION_TIMING};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

const ChildrenContainer = styled.div`
  padding-bottom: 4px;
  ${props => props.isActive && 'padding-left: 60px;'}
  ${ChildLink} {
    opacity: ${props => (props.isExpanded ? 1 : 0)};
  }
`

export const NewBadge = styled.span`
  ${TYPOGRAPHY.bodyXs};
  font-weight: 500;
  display: inline-block;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  border-radius: 4px;
  margin: 0 8px;
  padding: 0px 4px;
`

const NewVerticalBadge = styled(Text).attrs({
  variant: 'bodyXs',
  color: 'white'
})`
  display: flex;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 0px 4px 4px 0px;
  padding: 8px 0px 8px 0px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-orientation: downright;
`

const NewFeatureDot = styled.span`
  position: absolute;
  top: 9px;
  right: 12px;
  width: 6px;
  height: 6px;
  box-sizing: initial;
  background-color: ${props => props.theme.colors.primary};
  border: solid 2px ${props => props.theme.colors.white};
  border-radius: 50%;
  opacity: ${props => (props.collapsed ? '1' : '0')};
`

const NavIcon = ({ iconName }) => {
  return (
    <Icon
      name={iconName}
      color="grey200"
      margin="0 8px 0 14px"
      width="24px"
      height="24px"
    />
  )
}

const SidebarItem = props => {
  const handleParentClick = name => {
    const { expandSidebarOption } = props

    expandSidebarOption(name)
  }

  const isCurrentPath = ({ url, isCurrentPath }) => {
    const { currentPath } = props

    if (isCurrentPath) return isCurrentPath(currentPath)

    return !!currentPath.match(new RegExp(`^${url}(?!/)`))
  }

  const checkActiveStatus = item => {
    if (item.children) {
      return !isEmpty(compact(item.children.filter(checkActiveStatus)))
    }

    return isCurrentPath(item)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const renderChildren = (item, key) => {
    // NavLink expects a string value or undefined for the active prop
    const active = checkActiveStatus(item) ? item.url : undefined

    if (item.enabled === false) return null

    const { collapsed, enableWrapping } = props
    const { isNew } = item

    return (
      <ChildLink
        exact={!item.isCurrentPath}
        to={item.url}
        data-id={item.dataId}
        key={`sub-menu-${key}-${item.name}`}
        active={active}>
        <ItemText collapsed={collapsed} enableWrapping={enableWrapping}>
          {item.name}
          {isNew && <NewBadge>{isNew}</NewBadge>}
        </ItemText>
      </ChildLink>
    )
  }

  const renderParentItem = ({
    name,
    iconName,
    collapsed,
    isExpanded,
    isNew
  }) => {
    const { collapsed: sidebarCollapsed } = props
    return (
      <>
        <NavIcon iconName={iconName} />
        {isNew && <NewFeatureDot collapsed={sidebarCollapsed} />}
        <>
          <ItemText isParent collapsed={sidebarCollapsed}>
            {name}
            {isNew && <NewVerticalBadge>{isNew}</NewVerticalBadge>}
          </ItemText>
          {!collapsed && (
            <StyledChevronIcon
              margin="0 12px 0 0"
              direction={isExpanded ? 'up' : 'down'}
            />
          )}
        </>
      </>
    )
  }

  const {
    item,
    collapsed,
    ignoreToggleState,
    expandedOption,
    admin,
    flagData,
    appType,
    membershipInfo,
    permissions,
    isImpersonating
  } = props
  const {
    dataId,
    iconName,
    name,
    isNew,
    url,
    children,
    target,
    isExternal,
    onClick
  } = item

  const isActive = checkActiveStatus(item)
  const checkExpanded = () => {
    return expandedOption === name
  }
  const isExpanded = checkExpanded()
  const childrenContainerRef = useRef()

  const handleOnClick = () => {
    if (onClick) {
      onClick()
    }
  }

  if (
    !showSidebarItem({
      item,
      admin,
      appType,
      flagData,
      membershipInfo,
      permissions,
      isImpersonating
    })
  )
    return null

  if (isExternal) {
    return (
      <ItemWrapper
        isActive={isActive}
        collapsed={collapsed}
        onClick={handleOnClick}>
        <CustomAnchor
          href={url}
          target={target}
          data-id={dataId}
          item={{ ...item, isActive, collapsed, ignoreToggleState }}>
          <NavIcon iconName={iconName} />
          <ItemText collapsed={collapsed}>{name}</ItemText>
        </CustomAnchor>
      </ItemWrapper>
    )
  }

  // we do this validation check here instead of in the render method otherwise we can end up with a
  // parent with no children which function as a menu link which expands but should function as a link
  // e.g. the Launch menu item which should show the upgrade page if there are no children
  const filteredChildren = children
    ? children.filter(child =>
        showSidebarItem({
          item: child,
          admin,
          appType,
          flagData,
          membershipInfo,
          permissions
        })
      )
    : []

  if (filteredChildren.length > 0) {
    const updatedChildren = filteredChildren.map(child => {
      return {
        ...child,
        collapsed
      }
    })

    return (
      <>
        <ItemWrapper
          isActive={isActive}
          collapsed={collapsed}
          isExpanded={isExpanded}
          expandedHeight={
            NAV_ITEM_HEIGHT + childrenContainerRef?.current?.clientHeight || 0
          }
          isParent>
          <CustomNav
            active={isActive}
            data-id={dataId}
            onClick={() => handleParentClick(name, isExpanded)}
            item={{
              ...item,
              isActive,
              collapsed,
              ignoreToggleState,
              isExpanded
            }}>
            {renderParentItem({
              name,
              iconName,
              collapsed,
              isExpanded,
              isNew
            })}
          </CustomNav>
          <ChildrenContainer
            ref={childrenContainerRef}
            collapsed={collapsed}
            isExpanded={isExpanded}
            onClick={scrollToTop}>
            {updatedChildren.map((childItem, key) =>
              renderChildren(childItem, key)
            )}
          </ChildrenContainer>
        </ItemWrapper>
      </>
    )
  }

  return (
    <>
      <ItemWrapper
        isActive={isActive}
        collapsed={collapsed}
        onClick={scrollToTop}>
        <CustomNavLink
          exact={!item.isCurrentPath}
          to={url}
          data-id={dataId}
          target={target}
          item={{
            ...item,
            isActive,
            collapsed,
            ignoreToggleState
          }}>
          <NavIcon iconName={iconName} />
          {isNew && <NewFeatureDot collapsed={collapsed} />}
          <ItemText collapsed={collapsed}>
            {name} {isNew && <NewVerticalBadge>{isNew}</NewVerticalBadge>}
          </ItemText>
        </CustomNavLink>
      </ItemWrapper>
    </>
  )
}

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
  ignoreToggleState: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  enableWrapping: PropTypes.bool,
  expandedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  admin: PropTypes.bool,
  flagData: flagDataTypes,
  appType: PropTypes.string,
  membershipInfo: PropTypes.object,
  expandSidebarOption: PropTypes.func,
  currentPath: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  isNew: PropTypes.string,
  isImpersonating: PropTypes.bool
}

SidebarItem.defaultProps = {
  flagData: {},
  isNew: undefined,
  collapsed: false,
  ignoreToggleState: false,
  enableWrapping: false,
  admin: false,
  expandedOption: null,
  membershipInfo: undefined,
  expandSidebarOption: undefined,
  appType: undefined,
  permissions: {},
  isImpersonating: false
}

export default withTranslation('sidebar')(SidebarItem)
