import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Tooltip, Icon } from '@junglescout/edna'

const IconTooltip = styled.div`
  display: flex;
  gap: 4px;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UnsupportedCategoryCell = () => {
  const { t } = useTranslation('generic')
  const { colors } = useTheme()

  return (
    <IconTooltip>
      <div>-</div>
      <Tooltip
        content={t(
          'generic:subCategoryNotSupported',
          'This product category is not supported'
        )}
        side="top">
        <IconWrapper>
          <Icon
            name="TOOLTIP_INFO"
            color={colors.grey400}
            height="16px"
            width="16px"
          />
        </IconWrapper>
      </Tooltip>
    </IconTooltip>
  )
}
