import { datadogRum } from '@datadog/browser-rum'

export function initDatadog() {
  if (window.datadogRum) {
    // already initialized
    return
  }

  if (!process.env.REACT_APP_DATADOG_APPLICATION_ID) {
    return
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    env: process.env.REACT_APP_JUNGLE_ENV,
    sampleRate: 10,
    trackInteractions: true,
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    version: process.env.REACT_APP_DATADOG_VERSION || '1.0.0'
  })

  window.datadogRum = datadogRum
}
