import { TRANSITION_TIMING } from 'constants/sidebar'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Icon, Text } from '@junglescout/edna'
import { NakedButton } from 'ui_elements/NakedButton/NakedButton'

const FooterWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${props => (props.collapsed ? '12px 6px' : '12px 14px')};
`
const StyledIcon = styled(Icon)`
  transform: ${props => (props.$locked ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform ${TRANSITION_TIMING};
`

const CollapseButton = styled(NakedButton)`
  background-color: ${props => props.theme.colors.grey800};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.collapsed ? '6px 12px' : '6px 12px 6px 8px')};
  width: fill-available;
  &:hover {
    cursor: pointer;
  }
`

const TextWrapper = styled.div`
  display: inline-block;
  /* display: ${props => (props.collapsed ? 'none' : 'inline-block')}; */
  /* margin-left: 8px; */
  margin-left: ${props => (props.collapsed ? '0' : '8px')};
  overflow: hidden;
  white-space: nowrap;
  width: ${props => (props.collapsed ? '0' : 'inherit')};
  opacity: ${props => (props.collapsed ? 0 : 1)};
  transition: opacity 1s ease-in-out, width 0.3s ease-in-out;
`

const SidebarToggle = ({ collapsed, locked, onClick }) => {
  const { t } = useTranslation(['sidebar'])

  const text = locked
    ? t('sidebar:menu.collapseNavigation', 'Collapse Navigation')
    : t('sidebar:menu.expandNavigation', 'Expand Navigation')

  return (
    <FooterWrapper collapsed={collapsed}>
      <CollapseButton onClick={onClick} collapsed={collapsed}>
        <StyledIcon name="CHEVRON_RIGHT" color="grey200" $locked={locked} />
        <TextWrapper collapsed={collapsed}>
          <Text color="grey200" variant="bodyMd">
            {text}
          </Text>
        </TextWrapper>
      </CollapseButton>
    </FooterWrapper>
  )
}

SidebarToggle.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default withTheme(SidebarToggle)
