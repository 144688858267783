export function setChurnZeroUserData(user) {
  if (!user || !window.ChurnZero) {
    return
  }

  window.ChurnZero.push(['setContact', user.salesforce_id, user.id])
}

export function clearChurnZero() {
  if (!window.ChurnZero) {
    return
  }

  window.ChurnZero.push(['stop'])
}
